<template>
  <div class="news">
    <img class="bg" src="@/assets/image/pc/home/新闻背景.png">
    <div class="title">
      <div class="title_en">NEWS</div>
      <div class="title_zh">新闻资讯</div>
    </div>
    <div
      class="newslist"
      :finished="finished"
      finished-text="暂无数据了"
      @load="onLoad"
    >
      <ul>
        <li v-for="news in list" :key="news.id" @click="clickNews(news.id)">
          <img :src="news.cover">
          <!-- <img src="@/assets/image/pc/home/new.jpg"> -->
          <div class="newskind" :style="{'background':news.color}"><p>{{ news.tag }}</p></div>
          <p class="newstitle">{{ news.title }}</p>
        </li>
      </ul>
    </div>
    <div class="more" @click="handleMoreClick('/newsdetails')">
      <div>
        <p>更多动态</p>
      </div>
    </div>
  </div>
</template>
<script>
import { reqGetNewsList, reqGetSetting } from '@/api/news'

export default ({
  name: "News",
  data() {
    return {
      newsList: [],
      list: [],
      loading: false,
      finished: false,
      queryForm: {
        channel: 'gg.com',
        pageSize: 6,
        currentPage: 1,
        hot: 1
      },
      querySetting: {
        key: "article_type"
      },
      default_value: [{
        id: 999999,
        color: "#FFFFFF",
        cover: require('@/assets/image/pc/home/white.png'),
        // cover: "",
        tag: "",
        title: ""
      }]
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    async onLoad() {
      this.loading = true;
      const res = await reqGetNewsList(this.queryForm)
      const resSetting = await reqGetSetting(this.querySetting)
      while (res.data.length < 6) {
        res.data = [...res.data, ...this.default_value]
        // console.log(res.data, "sss")
      }
      // console.log(resSetting.data)
      // console.log(resSetting.data.length)
      const resSettingJson = JSON.parse(resSetting.data)
      // console.log(resSettingJson)
      // eslint-disable-next-line no-array-constructor
      var mapList = new Array();
      for (var i = 0; i < resSettingJson.length; i++) {
        // console.log(settingList)
        if (resSettingJson[i].key === "gg.com") {
          // console.log(resSettingJson[i].value)
          mapList = resSettingJson[i].value
          break;
        }
      }
      // console.log(mapList)
      var newsKindMap = new Map();
      var newsKindColorMap = new Map();
      for (var j = 0; j < mapList.length; j++) {
        newsKindMap.set(mapList[j].value, mapList[j].label)
        if (mapList[j].value === "news") {
          newsKindColorMap.set(mapList[j].value, "#2394C5")
        } else if (mapList[j].value === "gameWalkthrough") {
          newsKindColorMap.set(mapList[j].value, "#6A22CD")
        } else if (mapList[j].value === "announcement") {
          newsKindColorMap.set(mapList[j].value, "#EB3644")
        }
      }
      // console.log(newsKindMap)
      for (var k = 0; k < res.data.length; k++) {
        if (newsKindMap.has(res.data[k].tag)) {
          res.data[k].color = newsKindColorMap.get(res.data[k].tag)
          res.data[k].tag = newsKindMap.get(res.data[k].tag)
        }
      }
      // console.log(res.data)
      this.list = [...this.list, ...res.data]
      this.queryForm.currentPage = res.currentPage + 1
      this.loading = false;
      if (this.list.length >= res.count) {
        this.finished = true;
      }
    },
    clickNews(id) {
      if (id === 999999) {
        this.$router.push({
          path: '/'
        })
        return
      }
      this.$router.push({
        path: '/newsdetailspage',
        query: {
          id
        }
      })
    },
    handleMoreClick(path) {
      this.$router.push({
        path
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.news {
  width: 100%;
  height: 1253px;
  .bg {
    left: 0;
      height: 1253px;
    position: absolute;
  }
  .title{
    position: absolute;
    .title_en {
      text-align: center;
      margin-top: 30px;
      margin-left: 844px;
      font-size: 80px;
      font-family: Poppins;
      font-weight: 800;
      color: #343434;
      line-height: 104px;
      opacity: 0.76;
    }
    .title_zh {
      text-align: center;
      margin-left: 844px;
      margin-top: 8px;
      font-size: 35px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #EB3644;
    }
  }
  .newslist {
    position: absolute;
    left: 161px;
    margin-top: 218px;
    ul{
      list-style: none;
      width: 1598px;
      margin: auto;
      padding:0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li{
        text-align: center;
        width: 501px;
        height: 461px;
        border-top: 1px solid #CCCCCC;
        background: #FFFFFF;
        img{
          width: 501px;
          height: 283px;
          cursor: pointer;
        }
        .newskind{
          margin-top: 36px;
          margin-left: 220px;
          width: 64px;
          height: 30px;
          background: #6A22CD;
          border-radius: 3px;
          p{
            padding: auto;
            padding-top: 3px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FEF6EB;
          }
        }
        .newstitle{
          cursor: pointer;
          margin-top: 7px;
          margin: 7px auto;
          width: 400px;
          font-size: 21px;
          font-family: Microsoft YaHei;
          font-weight: 300;
          color: #000052;
        }
      }
    }
  }
  .more{
    top: 1173px;
    position: relative;
    width: 100%;
    div{
      width: 167px;
      height: 51px;
      background: #E60012;
      border-radius: 26px;
      margin-left: 874px;
      cursor: pointer;
      p{
        text-align: center;
        cursor: pointer;
        padding-top: 13px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}
</style>
