<template>
  <div class="career">
    <img class="bg" src="@/assets/image/pc/home/join us背景.png">
    <div class="title">
      <p class="title_en">JOIN US</p>
      <p class="title_zh">加入我们</p>
    </div>
    <div class="moreButton" @click="handleCareerClick('/career')">
      <div class="button">
        <p>查看所有职位空缺</p>
      </div>
    </div>
  </div>
</template>
<script>

export default ({
  name: "Career",
  methods: {
    handleCareerClick(path) {
      this.$router.push({
        path: path
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.career{
  width: 1920px;
  height: 790px;
  .bg{
    left: 0;
    width: 1920px;
    height: 790px;
    position: absolute;
  }
  .title{
    position: absolute;
    margin-top: 336px;
    .title_en{
      text-align: center;
      margin-left: 796px;
      font-size: 80px;
      font-family: Poppins;
      font-weight: 800;
      color: #FDFEFA;
      line-height: 60px;
      opacity: 0.76;
    }
    .title_zh{
      text-align: center;
      margin-top: 40px;
      margin-left: 800px;
      font-size: 35px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FDFEFA;
    }
  }
  .moreButton{
    margin-top: 556px;
    position: absolute;
    .button{
      margin-left: 816px;
      width: 295px;
      height: 72px;
      background: #E60012;
      border-radius: 36px;
      cursor: pointer;
      p{
        text-align: center;
        padding-top: 20px;
        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FDFEFA;
        cursor: pointer;
      }
    }
  }
}
</style>
